import { HttpMethods, sendRequest } from "../util/ApiHelper"

const CONTROLLER_NAME = "SchedulingAssignments";

export interface CalendarEvent  {
    title: string;
    start: string | Date;
    end: string | Date;
};

export const GetScheduleForEmployee = async (employeeId: string) => {
    const filter = `employee_id eq ${employeeId}`;
    return await sendRequest(
        HttpMethods.GET,
        `${CONTROLLER_NAME}/Get?filter=${filter}&fromMobile=true`
    )
}